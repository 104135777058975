import React from 'react';
import SEO from '../components/seo';

import Layout from '../components/fr';

const ProductConfirmation = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      title="Confirmation de commande - Furlan Snowboards"
      description="Votre commande est complete!"
      pathname="/fr/product-confirmation/"
      keywords={['snowboard', 'boards', 'polyvalence']}
    />
    <div className="jumbotron">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-12 mt-5">
            <h1 className="text-center mt-5">Finalisation</h1>
            <p className="lead text-center">
              Paiement accepté, nous vous confirmons sa réception rapidement.
              Veuillez finaliser votre commande.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3 mb-5">
          <form
            method="post"
            name="order-fr"
            action="/fr/success"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="order-fr" />
            <div className="form-group">
              <label>Téléphone (requis)</label>
              <input
                className="form-control form-control-lg"
                type="text"
                required
                name="phone"
                id="phone"
                placeholder="Votre téléphone ..."
              />
            </div>
            <div className="form-group">
              <label>Adresse de livraison (si différente de la facturation)</label>
              <textarea
                className="form-control form-control-lg"
                name="shipping"
                id="shipping"
                rows="5"
                placeholder="Votre adresse ..."
              />
            </div>
            <div className="form-group">
              <label>Code Promo</label>
              <input
                className="form-control form-control-lg"
                type="text"
                name="promo"
                id="promo"
                placeholder="Coder promo ..."
              />
            </div>
            <button className="btn btn-warning btn-lg mt-3 mr-3" type="submit">
              Finaliser
            </button>
            <input
              className="btn btn-primary btn-lg mt-3"
              type="reset"
              value="Effacer"
            />
          </form>
        </div>
      </div>
    </div>
  </Layout>
);

export default ProductConfirmation;
